import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import { OutboundLink } from "gatsby-plugin-google-analytics"


const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "banner3.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const scrollToBottom = () => {
    const el = document.getElementById('video-section')
    el.scrollIntoView({ behavior: 'smooth' })
  }
  
  return (
    <Layout>
      <BackgroundImage style={{opacity: ".6"}} className={"photo-background"} fluid={data.backgroundImage.childImageSharp.fluid}>
        <div className="background">
        <SEO title="Moving Towers" />
        <div className="intro">
          <h1>Moving Towers</h1>
          <p>A game by Ben Knight</p>
          <p className="test-build">Download the desktop test build</p>
          {/* <p className="small-text">Note: This game only runs on desktop</p> */}
          <div className="download-buttons">
            <OutboundLink target="_blank" rel="noopener noreferrer" href="https://storage.cloud.google.com/moving-towers-builds/MovingTowersMac1.0.app.zip" className="download-button">Download for Mac</OutboundLink>
            <OutboundLink target="_blank" rel="noopener noreferrer" href="https://storage.cloud.google.com/moving-towers-builds/MovingTowersWindows1.0.zip" className="download-button">Download for Windows</OutboundLink>
          </div>
          <div class="see-more" onClick={scrollToBottom}>
            <svg id="more-arrows">
              <polygon className="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
              <polygon className="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
              <polygon className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
            </svg>
          </div>
        </div>
        </div>
      </BackgroundImage>
      <div id="video-section">
        <iframe title="tutorial video" width="560" height="315" src="https://www.youtube.com/embed/1RwjkTpiygw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <div className="about-game">
          <h2>Thank you for checking out my game!</h2>
          <p>I’ve always loved the strategic flow of board games and MOBAs, but I’ve often wished they were faster paced. With Moving Towers I wanted to create a game in which players must constantly balance tense action-oriented combat with methodical long-term strategy. <br></br><br></br>I hope you enjoy playing! Please let me know if you have any feedback, positive or negative. You can contact me through the <a href="https://discord.gg/j2n6z2W" target="_blank" rel="noopener noreferrer">Moving Towers Discord</a> or you can <a href="mailto:ben@movingtowers.com">email me</a>. Thanks for playing my game!</p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
