import React from "react"
import PropTypes from "prop-types"

import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main className="main">
          {children}
        </main>
        <footer>
          <div className="footerContent">
            <div>Made in Seattle, WA</div>
            <div>|</div>
            <div>ben@movingtowers.com</div>
            <div>|</div>
            <div>@benknght</div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
